import { CurrencyISOCode } from '@dougs/core/config-back';
import { VendorInvoice, VendorInvoicePaymentStatus } from '../dto';

const getDefaults = (): VendorInvoice => ({
  id: '1',
  companyId: 1,
  amount: 1,
  memo: '',
  createdAt: new Date('2023-01-30'),
  operations: [],
  sourceDocumentId: 1,
  paymentStatus: VendorInvoicePaymentStatus.PAID,
  updatedAt: new Date('2023-01-30'),
  label: '',
  fileName: '',
  filePath: '',
  fileType: '',
  amountTva: 0,
  currency: CurrencyISOCode.EUR,
  supplierName: 'supplier',
  date: '2023-01-30',
  isRefund: false,
  receiptId: 1,
  accrualOperationAttachment: null,
  operationAttachments: [],
});

export const getVendorInvoiceMock = (p?: Partial<VendorInvoice>): VendorInvoice => ({
  ...getDefaults(),
  ...p,
});
